
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { PageType } from "../../interfaces/Pages";
import PageContainer from "../PageContainer/PageContainer";

class ErrorBoundaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // (this as any).props.history.push("/error");
    return { hasError: true };
  }

  render() {
    if ((this.state as any).hasError) {
      return (
        <PageContainer recContent={(this.props as any).content[PageType.error]}>
          <div></div>
        </PageContainer>
      );
    }

    return this.props.children;
  }
}

export default withRouter(
  connect((state) => ({ content: state.content }))(ErrorBoundaryPage)
);


/*
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { PageType } from "../../interfaces/Pages";
import PageContainer from "../PageContainer/PageContainer";

// Define interface for the component's props
interface Props extends RouteComponentProps {
  content: {
    [key in PageType]: any;  // Replace 'any' with your actual content type if known
  };
  children?: React.ReactNode;  // Add children to props interface
}

// Define interface for the component's state
interface State {
  hasError: boolean;
}

// Add type parameters to Component
class ErrorBoundaryPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // this.props.history.push("/error");  // Now typed correctly
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageContainer recContent={this.props.content[PageType.error]}>
          <div></div>
        </PageContainer>
      );
    }
    return this.props.children;
  }
}

// Type the connect function
const mapStateToProps = (state: any) => ({  // Replace 'any' with your actual state type
  content: state.content
});

export default withRouter(
  connect(mapStateToProps)(ErrorBoundaryPage)
);
*/
